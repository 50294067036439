// extracted by mini-css-extract-plugin
export var Triangle1 = "Careers-module--Triangle1--Vfotb";
export var Triangle2 = "Careers-module--Triangle2--2F3wc";
export var card = "Careers-module--card--36dCT";
export var cards = "Careers-module--cards--3rNrE";
export var careerHeader = "Careers-module--careerHeader--3dzrd";
export var careerOpenRoles = "Careers-module--careerOpenRoles--bQyZj";
export var careerOpenings = "Careers-module--careerOpenings--2uXJZ";
export var careerOpeningsPostscript = "Careers-module--careerOpeningsPostscript--1_FLk";
export var section = "Careers-module--section--3U5Q6";
export var sectionSecondFold = "Careers-module--sectionSecondFold--29dwj";
export var showIn = "Careers-module--show-in--1IWnl";
export var smallTitle = "Careers-module--smallTitle--19H23";
export var subtitle = "Careers-module--subtitle--2hLxt";
export var title = "Careers-module--title--2qfYL";