// extracted by mini-css-extract-plugin
export var active = "Cards-module--active--2ZzSS";
export var cards = "Cards-module--cards--2TOmt";
export var cardsList = "Cards-module--cardsList--3ODAx";
export var cardsListItem = "Cards-module--cardsListItem--3aszo";
export var cardsListItemIcon = "Cards-module--cardsListItemIcon--B_pLG";
export var cardsListItemSubtitle = "Cards-module--cardsListItemSubtitle--2XEgp";
export var cardsListItemTitle = "Cards-module--cardsListItemTitle--2VDbT";
export var cardsWrapper = "Cards-module--cardsWrapper--1RBVw";
export var lightBlue = "Cards-module--lightBlue--3yA-a";
export var showIn = "Cards-module--show-in--2l1VL";
export var white = "Cards-module--white--3sGaz";