import React from "react"

import Cards from "@src/components/Cards"
import Section from "@src/components/Section"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import FeatureGrid from "@src/components/FeatureGrid"
import Text from "@src/components/Text"
import CommunityCallout from "@src/components/CommunityCallout"
import Layout from "@src/components/Layout"

import UserFirstIcon from "@src/icons/user-first.svg"
import OwnershipIcon from "@src/icons/ownership.svg"
import PassionIcon from "@src/icons/unexplored.svg"
import ExceptionalIcon from "@src/icons/ethics.svg"
import DiversityIcon from "@src/icons/diversity.svg"
import MasterIcon from "@src/icons/master.svg"
import Trainagle from "@src/icons/triangle.svg"
import OG from "@src/icons/og/OG_Career.jpeg"

import Openings from "./components/Openings"
import * as styles from "./Careers.module.scss"
import Splash from "../Features/components/Splash"
import Row from "../components/Row"
import Features from "../Features/components/Features"

const Careers = () => {
  return (
    <Layout
      title="Careers"
      description="Join us as we fundamentally change how we interact with the backend."
      url="https://canonic.dev/careers"
      ogImage={OG}
    >
      <Splash
        title="Careers"
        subtitle="Interested in being a part of the backend revolution?"
      />
      <Row>
        <h2 className={styles.title}>What we value</h2>
        <p className={styles.subtitle}>
          We're a tightly knit team of like-minded individuals.
        </p>
        <Features
          // horizontal
          numPerRow={3}
          features={[
            {
              icon: ExceptionalIcon,
              title: "Be exceptional, but also ethical & kind.",
              subtitle:
                "We like to make decisions with integrity and kindness. Strong ethics are the core of Canonic.",
            },
            {
              icon: OwnershipIcon,
              title: "You're your own boss. Own it like it's hot.",
              subtitle:
                "We believe in distributed decision making. If you want to own it, it's yours. But remember, it's a baby!",
            },
            {
              icon: DiversityIcon,
              title: "Diversity & creativity lead to magic.",
              subtitle:
                "When a diverse people from different backgrounds and thinking come together, it's like nothing else.",
            },
            {
              icon: UserFirstIcon,
              title: "Make the user's experience frictionless.",
              subtitle:
                "Their problems are our problems. All decisions and priorities stem from our user's problems.",
            },
            {
              icon: PassionIcon,
              title: "Infectious passion to go into the unexplored.",
              subtitle:
                "We are passionate about the latest and greatest. We like to dive into the deep end and explore the unexplored.",
            },

            {
              icon: MasterIcon,
              title: "Master of one, jack of all trades.",
              subtitle:
                "We love people who master their trades, but why stop there? Fresh perspectives arise when cross-skilling.",
            },
          ]}
        />
      </Row>
      <Row>
        <h2 className={styles.title}>
          Ready to be a part of the Canonic Force?
        </h2>
        <p className={styles.subtitle}>
          We're looking to grow our team and are actively recruiting for the
          positions listed below:
        </p>
        <Openings
          openings={[
            {
              title: "Sr. Software Engineer",
              department: "Technology ",
            },
            {
              title: "Software Engineer",
              department: "Technology ",
            },
            {
              title: "Digital Marketer",
              department: "Marketing",
            },
            {
              title: "Marketing Intern",
              department: "Marketing",
              term: "Internship",
            },

            {
              title: "Business Development",
              department: "Sales",
            },
            {
              title: "Product Management ",
              department: "Product",
            },
          ]}
        />
        <Text.Subtitle className={styles.careerOpeningsPostscript}>
          Don't fit any of the above and still feel you fit? We are always
          looking to infuse something new. Email us at{" "}
          <a href="mailto:hello@canonic.dev">hello@canonic.dev</a> with your
          resumé and we would love to chat.
        </Text.Subtitle>
      </Row>
      <Row>
        <h2 className={styles.title}>Perks of Working with Us</h2>
        <p className={styles.subtitle}>
          We want to see you grow and enjoy working at Canonic
        </p>
        <Cards
          className={styles.cards}
          cardClassName={styles.card}
          items={[
            {
              title: "🏠 Remote Optional",
            },
            {
              title: "🐶 Dog-friendly Office",
            },
            {
              title: "🛫 Goal oriented, unlimited leaves!",
            },
            {
              title: "💻 If you need equipment, we get it for you.",
            },
            {
              title: "🌴 Annual company offsites.",
            },
            {
              title: "❤️ A small team that cares!",
            },
            {
              title: "🙌 We want to see you grow. Bi-weekly 1-1s.",
            },
          ]}
        />
      </Row>
      <CommunityCallout />
    </Layout>
  )
}

export default Careers
