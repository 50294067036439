import React from "react";

export default function useParallaxHover(perspective = 1000) {
  const outerRef = React.useRef();
  const innerRef = React.useRef();

  const handleMouseEnter = React.useCallback((e) => {
    const innerEl = innerRef.current;
    setTimeout(() => (innerEl.style.transition = ""), 100);
    innerEl.style.transition = "transform 0.1s";
  }, []);

  const handleMouseMove = React.useCallback(
    (e) => {
      const innerEl = innerRef.current;
      const outerEl = outerRef.current;
      const mouseX = e.pageX - 100;
      const mouseY = e.pageY;
      const centerX = outerEl.offsetLeft + outerEl.clientWidth / 2;
      const centerY = outerEl.offsetTop + outerEl.clientHeight / 2;
      const percentX = (mouseX - centerX) / (outerEl.clientWidth / 2);
      const percentY = -((mouseY - centerY) / outerEl.clientHeight / 2);

      innerEl.style.transform = `perspective(${perspective}px) rotateY(${percentX}deg) rotateX(${percentY}deg) scale3d(1.008, 1.008, 1)`;
    },
    [perspective]
  );

  const handleMouseLeave = React.useCallback(
    (e) => {
      const innerEl = innerRef.current;
      innerEl.style.transition = "transform 0.1s";
      innerEl.style.transform = `perspective(${perspective}px) rotateY(0deg) rotateX(0deg)`;
      setTimeout(() => (innerEl.style.transition = ""), 100);
    },
    [perspective]
  );

  return {
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove,
    innerRef,
    outerRef,
  };
}
