import React from "react"
import activateOnScreen from "@src/hoc/activateOnScreen"
import Text from "@src/components/Text"
import * as styles from "./Cards.module.scss"

const Cards = activateOnScreen(
  ({
    active,
    innerRef,
    items,
    title,
    lightBlue,
    className,
    cardClassName,
    id,
    link,
  }) => {
    const activeClassName = active ? styles.active : ""
    const lightBlueClassName = lightBlue ? styles.lightBlue : ""

    return (
      <section
        id={id}
        ref={innerRef}
        blueGradient
        className={`${styles.cards} ${activeClassName} ${className} ${lightBlueClassName} `}
      >
        <div className={styles.cardsWrapper}>
          {title && <Text.H2>{title}</Text.H2>}
          <div className={styles.cardsList}>
            {items.map(({ icon, title, subtitle, link }) => (
              <article
                key={title}
                className={`${styles.cardsListItem} ${cardClassName}`}
              >
                {icon && (
                  <figure className={styles.cardsListItemIcon}>
                    <img alt={title} src={icon} />
                  </figure>
                )}
                {title && (
                  <Text.H4 className={styles.cardsListItemTitle}>
                    {title}
                  </Text.H4>
                )}
                <Text.P
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                  className={styles.cardsListItemSubtitle}
                />
                {link && (
                  <a href={link.url} target="_blank" className={styles.link}>
                    {link.title}
                  </a>
                )}
              </article>
            ))}
          </div>
        </div>
      </section>
    )
  }
)

export default Cards
