// extracted by mini-css-extract-plugin
export var active = "Openings-module--active--3XxbD";
export var opening = "Openings-module--opening--1_e9y";
export var openingDescription = "Openings-module--openingDescription--2yJTS";
export var openingFooter = "Openings-module--openingFooter--d6QdH";
export var openingInner = "Openings-module--openingInner--bIXoJ";
export var openingSubtitle = "Openings-module--openingSubtitle--DzUUK";
export var openingTitle = "Openings-module--openingTitle--XpJRK";
export var openings = "Openings-module--openings--bz5Hg";
export var openingsList = "Openings-module--openingsList--3NLxV";
export var openingsSubtitle = "Openings-module--openingsSubtitle--2X5j6";
export var openingsTitle = "Openings-module--openingsTitle--36_gg";
export var openingsWrapper = "Openings-module--openingsWrapper--24ksA";
export var showIn = "Openings-module--show-in--13MjF";