import React from "react"

import Text from "@src/components/Text"
import Section from "@src/components/Section"
import activateOnScreen from "@src/hoc/activateOnScreen"
import useParallaxHover from "@src/hooks/useParallaxHover"
import * as styles from "./Openings.module.scss"

const Openings = activateOnScreen(({ active, innerRef, openings }) => {
  const activeClassName = active ? styles.active : ""
  return (
    <section ref={innerRef} className={styles.openingsWrapper}>
      <div className={`${styles.openings} ${activeClassName}`}>
        <div className={styles.openingsList}>
          {openings.map(({ title, description, department, term }) => (
            <Opening
              key={title}
              title={title}
              description={description}
              department={department}
              term={term}
            />
          ))}
        </div>
      </div>
    </section>
  )
})

const Opening = ({
  title,
  location = "New Delhi | Remote",
  description,
  department,
  term = "Full Time",
}) => {
  const {
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove,
    innerRef,
    outerRef,
  } = useParallaxHover()

  return (
    <article
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={outerRef}
      className={styles.opening}
      onClick={() =>
        window.open("https://angel.co/company/canonic/jobs", "_blank")
      }
    >
      <div className={styles.openingInner} ref={innerRef}>
        <Text.H4 className={styles.openingTitle}>{title}</Text.H4>
        <Text.H5 className={styles.openingSubtitle}>{location}</Text.H5>
        <Text.P className={styles.openingDescription}>{description}</Text.P>
        <div className={styles.openingFooter}>
          <Text.H5>{department}</Text.H5>
          <Text.H5>{term}</Text.H5>
        </div>
      </div>
    </article>
  )
}

export default Openings
